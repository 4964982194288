import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../components/Layout'
import ListadoReservas from '../components/ListadoReservas';
import NuevaReserva from '../components/NuevaReserva';
import { motion } from 'framer-motion';
import authContext from '../context/auth/authContext'
import appContext from '../context/auth/appContext';


const Reservas = () => {
    const [ nuevaReserva, setNuevaReserva ] = useState(false);
    const AuthContext = useContext(authContext);
    const { setOpenLogin, setVerLogin } = useContext(appContext);

    const { autenticado } = AuthContext;
    const mail = localStorage.getItem('mail');
    const areaAnimate={
        offscreen:{x:0, opacity:0},
        onscreen:{
            x:0, 
            opacity:1,
            transition : {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
        <Layout
            pagina="Reservas"
        >
            <main className='w-full h-full bg-[url("https://lideraturismo.tur.ar/lidera/img/logos/logo.png")] bg-repeat'>
                <div className='bg-indigo-800/90 w-full h-full pb-10 pt-2 mt-0'>
                    <h1 className='mt-5 text-white text-5xl text-center'>Reservas</h1>
                    {   mail ? (
                        nuevaReserva ? (
                        <div className='md:w-1/2 w-10/12 mx-auto md:p-10 p-3 mt-5'>
                            <NuevaReserva tipo={1} />
                            <button className='bg-orange-600 text-white mt-10 p-3 rounded-xl uppercase' onClick={() => setNuevaReserva(false)}>Cancelar</button>
                        </div>
                        )
                     : (
                        <div className='md:w-2/3 w-full mx-auto md:p-5 p-1 mt-9'>
                            <ListadoReservas />
                            <motion.button 
                                className='bg-orange-600 hover:bg-orange-500 text-white mt-10 p-3 rounded-xl uppercase' 
                                onClick={() => setNuevaReserva(true)}
                                initial={"offscreen"}
                                whileInView={"onscreen"}
                                viewport={{once:true, amount: 0.1}}
                                variants={areaAnimate}                    
                            >
                                Nueva Reserva
                            </motion.button>
                        </div>
                        )
                        ):(
                        <div className='w-full'>
                            <p className='text-3xl text-white mt-10 mb-10 text-center'>Para generar Reservas debe iniciar sesión con su Usuario. En caso que no posea un Usuario, puede crear uno.</p>
                            <div className='w-10/12 sm:w-1/2 md:w-1/4 mx-auto'>
                                <button className='w-full bg-orange-600 mt-10 mb-52 rounded-xl'
                                    onClick={() => {
                                        setOpenLogin(true)
                                        setVerLogin(true)
                                    }}
                                >
                                    <p className=' w-full mx-auto text-white p-3 uppercase text-center'>
                                        Iniciar Sesión o Crear Usuario
                                    </p>
                                </button>

                            </div>
                        </div>
                        )                    
                    }
                </div>
            </main>
        </Layout>
  )
}

export default Reservas