import Layout from '../components/Layout'
import SliderPrincipal from '../components/SliderPrincipal'
import Turismos from '../components/Turismos'
import Paquetes from '../components/Paquetes'
import Eventos from '../components/Eventos'
import imgExterna from '../assets/Rentourist_logofinal_png01.png'
import { motion } from 'framer-motion';
import { areaAnimateLR, areaAnimateRL, areaAnimateS } from '../components/Animaciones';

export default function Home() {
	return (
		<Layout
			pagina=""
		>
			<main className='h-full'>
				<div className='w-full h-full bg-black'>
					<SliderPrincipal />
					<motion.h3
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateRL}
						className='text-white text-5xl text-center my-10'>Turismo internacional</motion.h3>
					<div className='w-full md:flex mx-auto mt-2'>
						<Eventos tipo={7} />
					</div>
					<motion.h3
						initial={"offscreen"}
						whileInView={"onscreen"}
						viewport={{ once: true, amount: 0.1 }}
						variants={areaAnimateRL}
						className='text-white text-5xl text-center my-10'>Turismo Nacional</motion.h3>
					<div className='w-full md:flex mx-auto mt-2 mb-24'>
						<Eventos tipo={10} />
					</div>
					<div className='w-11/12 md:flex bg-black mx-auto'>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={1}
								lnk={'estudiantil'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={2}
								lnk={'grupal'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={3}
								lnk={'nacional'}
							/>
						</div>
						<div className='w-full md:w-1/4 h-96 p-3'>
							<Turismos
								idservicio={4}
								lnk={'internacional'}
							/>
						</div>
					</div>
					<div className='w-full flex pb-24'>
						<Paquetes />
					</div>
					<div className='w-full pb-24'>
						<h3 className='text-white text-center text-4xl'>¿Necesitas algo para tu viaje?</h3>
						<p className='text-white text-center text-3xl mt-5'>Encontrá lo que buscas en</p>
						<a
							href={"https://www.rentourist.com.ar"}
							target='_blank'
						>
							<img className="rounded-xl w-9/12 md:w-1/4 mx-auto object-cover mt-10" src={imgExterna} alt="img"></img>
						</a>
					</div>

				</div>
			</main>

		</Layout>
	)
}
