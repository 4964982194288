import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Url } from './Url';


const ListadoReservas = () => {
    const [reservas, setReservas] = useState([]);
    const mail = localStorage.getItem('mail');

    const res = async () => {
        const url = `${Url}api/listadoreservas.php?mail=${mail}`;
        const resultado = await axios.get(url);
        //console.log(resultado)
        setReservas(resultado.data.results);
    }

    useEffect(() => {
        res();
    }, [])

    const areaAnimate = {
        offscreen: { x: 0, opacity: 0 },
        onscreen: {
            x: 0,
            opacity: 1,
            transition: {
                type: "spring",
                bounce: 0.4,
                duration: 4
            }
        }
    }

    return (
        <motion.div className='bg-orange-500 rounded-2xl p-3'
            initial={"offscreen"}
            whileInView={"onscreen"}
            viewport={{ once: true, amount: 0.1 }}
            variants={areaAnimate}
        >
            <h3 className='text-white text-2xl text-center mb-5'>Mis Reservas</h3>
            <table className='table-auto border-collapse border-spacing-5 border border-slate-400 text-white rounded-xl mx-auto' >
                <thead >
                    <tr>
                        <th className='border border-slate-300 p-2'>Fecha de Reserva</th>
                        <th className='border border-slate-300 p-2'>Apellido</th>
                        <th className='border border-slate-300 p-2'>Nombre</th>
                        <th className='border border-slate-300 p-2'>Colegio / Curso</th>
                        <th className='border border-slate-300 p-2'>Paquete</th>
                    </tr>
                </thead>
                <tbody >
                    {
                        typeof reservas !== 'undefined' ? (
                            reservas.map((reserva, index) => (
                                <tr key={index}>
                                    <td className='border border-slate-300 p-2'>{reserva.fechacontrato}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.apellido}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.nombre}</td>
                                    <td className='border border-slate-300 p-2'>{reserva.datoscolegio}</td>
                                    <td className='border border-slate-300 p-2'>{`${reserva.nombrepaquete} - ${reserva.duracion}`}</td>
                                </tr>
                            ))
                        ) : (null)
                    }
                </tbody>
            </table>
        </motion.div>
    )
}

export default ListadoReservas